import React from 'react';
import SimpleMarkdown, {sanitizeUrl, reactElement, htmlTag} from 'simple-markdown';
import { useStaticQuery, graphql } from "gatsby"

var LIST_BULLET = "(?:[*+-]|\\d+\\.)";
var LIST_ITEM_PREFIX = "( *)(" + LIST_BULLET + ") +";
var LIST_ITEM_PREFIX_R = new RegExp("^" + LIST_ITEM_PREFIX);
var LIST_ITEM_R = new RegExp(
    LIST_ITEM_PREFIX +
    "[^\\n]*(?:\\n" +
    "(?!\\1" + LIST_BULLET + " )[^\\n]*)*(\n|$)",
    "gm"
);
var BLOCK_END_R = /\n{2,}$/;
var LIST_BLOCK_END_R = BLOCK_END_R;
var LIST_ITEM_END_R = / *\n+$/;


const DOMAIN_LIST = [
    'parentwise.sg',
    'ntuc-deploy.web.app',
    'staging.parentwise.sg',
    'parentwise.d.ceegees.in',
    'dev.parentwise.d.ceegees.in',
];


const PWMarkdown = ({ data }) => {
    
    const definitionData = useStaticQuery(graphql`
        query DefinitionQuery {
            cms {
                definitions(first: 1000) {
                    nodes {
                        title
                        slug
                        acf_definition {
                            description
                        }
                    }
                }
            }
        }
    `);

    let definitionList = null;
    if (definitionData && definitionData.cms && definitionData.cms.definitions && definitionData.cms.definitions.nodes) {
        definitionList = definitionData.cms.definitions.nodes;
    }
    
    const positionInfoTip = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.target.classList.add("active");
        let isSuperScript = evt.target.parentNode.classList.contains("pw-super-script-tip");

        document.body.classList.add("toltip-active");
        
        const titleRect = evt.target.getBoundingClientRect();
        const toolTip = evt.target.nextSibling;

        const toolTipRect = toolTip.getBoundingClientRect();

        const widths = [window.innerWidth];
        if (window.screen?.width) {
            widths.push(window.screen?.width);
        }

        let screenWidth = Math.min(...widths);
        let offset = null;

        if (document.body.classList.contains('pop-up-active')) {
            let modalEle = document.getElementsByClassName('pw-pop-up-modal w3-show');

            if (modalEle && modalEle[0]) {
                if (modalEle[0].lastElementChild) {
                    let modalRect = modalEle[0].lastElementChild.getBoundingClientRect();
                    offset = modalRect;
                }
            }
        }

        var tipX = -10;
        if (isSuperScript) {
            tipX = -180;
        }

        if ((titleRect.x + toolTipRect.width) > screenWidth) {
            tipX = -((titleRect.x + toolTipRect.width + 10) - screenWidth);
        } else if (titleRect.x < 300) {
            tipX = tipX + (200 - (titleRect.x));
        }

        if (offset) {
            tipX = -10;

            if ((titleRect.x + toolTipRect.width) > (offset.x + offset.width)) {
                tipX = -((titleRect.x + toolTipRect.width + 10) - (offset.x + offset.width));
            } else if ((titleRect.x - offset.x) < 300) {
                tipX = tipX + 20 - ((titleRect.x - offset.x));
            }
        }

        toolTip.style.left = tipX + 'px';
    }

    const closeInfoTip = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        document.body.classList.remove("toltip-active");

        const targetTipElement = evt.target.parentNode.querySelector(".pw-info-title");
        if (targetTipElement) {
            targetTipElement.classList.remove("active");
        }
    }

    const openPopUp = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        const popUpModal = evt.target.nextSibling;
        popUpModal.classList.remove('w3-hide');
        popUpModal.classList.add('w3-show');
        document.body.classList.add("pop-up-active");
    }

    const closePopUp = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        document.body.classList.remove("pop-up-active");

        const targetElement = evt.target.parentNode;
        if (targetElement) {
            targetElement.parentNode.classList.remove("w3-show");
            targetElement.parentNode.classList.add("w3-hide");
        }
    }

    var rules = {
        ...SimpleMarkdown.defaultRules,
        paragraph: {
            ...SimpleMarkdown.defaultRules.paragraph,
            react: (node, output, state) => {
                return <p key={state.key}>{output(node.content, state)}</p>;
            }
        },
        list: {
            ...SimpleMarkdown.defaultRules.list,
            parse: function(capture, parse, state) {
                var bullet = capture[2];
                var ordered = bullet.length > 1;
                var start = ordered ? +bullet : undefined;
                var items = /** @type {string[]} */ (
                    capture[0]
                        .replace(LIST_BLOCK_END_R, "\n")
                        .match(LIST_ITEM_R)
                );
    
                // We know this will match here, because of how the regexes are
                // defined
                /*:: items = ((items : any) : Array<string>) */
    
                var lastItemWasAParagraph = false;
                var itemContent = items.map(function(/** @type {string} */ item, /** @type {number} */ i) {
                    // We need to see how far indented this item is:
                    var prefixCapture = LIST_ITEM_PREFIX_R.exec(item);
                    var space = prefixCapture ? prefixCapture[0].length : 0;
                    // And then we construct a regex to "unindent" the subsequent
                    // lines of the items by that amount:
                    var spaceRegex = new RegExp("^ {1," + space + "}", "gm");
    
                    // Before processing the item, we need a couple things
                    var content = item
                             // remove indents on trailing lines:
                            .replace(spaceRegex, '')
                             // remove the bullet:
                            .replace(LIST_ITEM_PREFIX_R, '');
    
                    // I'm not sur4 why this is necessary again?
                    /*:: items = ((items : any) : Array<string>) */
    
                    // Handling "loose" lists, like:
                    //
                    //  * this is wrapped in a paragraph
                    //
                    //  * as is this
                    //
                    //  * as is this
                    var isLastItem = (i === items.length - 1);
                    var containsBlocks = content.indexOf("\n\n") !== -1;
    
                    // Any element in a list is a block if it contains multiple
                    // newlines. The last element in the list can also be a block
                    // if the previous item in the list was a block (this is
                    // because non-last items in the list can end with \n\n, but
                    // the last item can't, so we just "inherit" this property
                    // from our previous element).
                    var thisItemIsAParagraph = containsBlocks ||
                            (isLastItem && lastItemWasAParagraph);
                    lastItemWasAParagraph = thisItemIsAParagraph;
    
                    // backup our state for restoration afterwards. We're going to
                    // want to set state._list to true, and state.inline depending
                    // on our list's looseness.
                    var oldStateInline = state.inline;
                    var oldStateList = state._list;
                    state._list = true;
    
                    // Parse inline if we're in a tight list, or block if we're in
                    // a loose list.
                    var adjustedContent;
                    if (thisItemIsAParagraph) {
                        state.inline = false;
                        adjustedContent = content.replace(LIST_ITEM_END_R, "\n\n");
                    } else {
                        state.inline = true;
                        adjustedContent = content.replace(LIST_ITEM_END_R, "");
                    }
    
                    var result = parse(adjustedContent, state);
    
                    // Restore our state before returning
                    state.inline = oldStateInline;
                    state._list = oldStateList;
                    return {
                        data: result,
                        prefix: prefixCapture[0].replace('. ', '')
                    };
                });
    
                return {
                    ordered: ordered,
                    start: start,
                    items: itemContent
                };
            },
            react: function(node, output, state) {
                var ListWrapper = node.ordered ? "ol" : "ul";
    
                return reactElement(
                    ListWrapper,
                    state.key,
                    {
                        start: node.start,
                        children: node.items.map(function(
                            /** @type {SimpleMarkdown.ASTNode} */ item,
                            /** @type {number} */ i
                        ) {
                            return reactElement(
                                'li',
                                '' + i,
                                {
                                    value: item.prefix,
                                    children: output(item.data, state)
                                }
                            );
                        })
                    }
                );
            },
            html: function(node, output, state) {
                var listItems = node.items.map(function(/** @type {SimpleMarkdown.ASTNode} */ item) {
                    return htmlTag("li", output(item.data, state), { value: item.prefix });
                }).join("");
    
                var listTag = node.ordered ? "ol" : "ul";
                var attributes = {
                    start: node.start
                };
                return htmlTag(listTag, listItems, attributes);
            }
        },
        link: {
            ...SimpleMarkdown.defaultRules.link,
            react: (node, output, state) => {
                let targetURL;
                try {
                    targetURL = new URL(node.target);
                } catch (_) {
                    targetURL = null;
                }

                let targetValue = '_self';
                if (targetURL && DOMAIN_LIST.indexOf(targetURL.hostname) === -1) {
                    targetValue = '_blank';
                }

                return reactElement(
                    'a',
                    state.key,
                    {
                        href: sanitizeUrl(node.target),
                        title: node.title,
                        children: output(node.content, state),
                        target: targetValue
                    }
                );
            }
        },
        lineBreak: {
            order: SimpleMarkdown.defaultRules.paragraph.order - 0.3,
            match: function(source) {
                return /^\\n/.exec(source);
            },
            parse: function(capture, recurseParse, state) {
                return {
                    content: recurseParse(capture[1], state)
                };
            },
            react: function() {
                return "\n";
            },
        },
        infoTip: {
            order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
            match: function(source) {
                // previous regex
                // return /^\[(.+?)\]\[(.+?)\]/.exec(source);
                
                // working regex
                return /^\[(([^\]\[]+)|(.+))\]\[(([^\]\[]+)|(.+))\]/.exec(source);
            },
            parse: function(capture) {
                return {
                    content: {
                        title: capture[1],
                        description: capture[4]
                    }
                };
            },
            react: function(node) {
                var blockSource = node.content.description + "\n\n";
                var parseTree = parser(blockSource.replace(/\\n/g, "\n"), {inline: false});
                let isSuperScript = false;
                if (node.content.title && !isNaN(node.content.title)) {
                    isSuperScript = true;
                } else if (node.content.title.indexOf('ss: ') === 0) {
                    isSuperScript = true;
                } 
  
                return (
                    <a href="javascript:void(0);" style={{ textDecoration: 'none', display: 'inline' }}>
                        <div className={`pw-info-tip-container ${isSuperScript ? 'pw-super-script-tip' : ''}`}>
                            <span className="pw-info-title" onClick={positionInfoTip}>
                                {(isSuperScript) ? node.content.title.replace('ss: ', '') : node.content.title}
                            </span>
                            <div className="pw-info-desc" onClick={evt => evt.stopPropagation()}>
                                {reactOutput(parseTree)}
                            </div>
                            {/* <div className="pw-info-overlay" onClick={closeInfoTip} /> */}
                        </div>
                    </a>
                );
            },
        },
        popUpDefinition: {
            order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
            match: function(source) {
                return /^\_\_([^__)]+)\_\_\(([^(^)]+)\)/.exec(source);
            },
            parse: function(capture) {
                return {
                    content: {
                        data: capture[0],
                        title: capture[1],
                        slug: capture[2]
                    }
                };
            },
            react: function(node) {
                if (!definitionList) {
                    return node.content.data;
                }
                
                const definition = definitionList.find((i) => i.slug === node.content.slug);
                if (!definition) {
                    return node.content.data;
                }

                var blockSource = definition.acf_definition.description + "\n\n";
                var parseTree = parser(blockSource, {inline: false});
  
                return (
                    <div style={{ display: 'inline' }}>
                        <div className={`pw-pop-up-container`}>
                            <span className="pw-pop-up-title" onClick={openPopUp}>
                                {node.content.title}
                            </span>
                            <div className="pw-pop-up-modal w3-modal w3-hide" onClick={evt => evt.stopPropagation()}>
                                <div className="pw-close-container"><button className="pw-close" onClick={closePopUp} /></div>
                                <div className="w3-modal-content">
                                    {reactOutput(parseTree)}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        superScript: {
            order: SimpleMarkdown.defaultRules.link.order + 0.1,
            match: function(source) {
                return /^\[(.+?)\]/.exec(source);
            },
            parse: function(capture) {
                return {
                    content: capture[0],
                    string: capture[1]
                };
            },
            react: function(node) {
                let isBlackSuperScript = false;
                if (node.string.indexOf('b: ') === 0) {
                    isBlackSuperScript = true;
                }

                return (
                    <span className={`pw-super-script ${(isBlackSuperScript) ? 'pw-black-ss' : ''}`}>
                        {(isBlackSuperScript) ?
                            node.string.replace('b: ', '')
                            : node.content
                        }
                    </span>
                );
            },
        }
    };
    
    var parser = SimpleMarkdown.parserFor(rules);
    var reactOutput = SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, 'react'));
    
    var blockSource = data + "\n\n";
    var parseTree = parser(blockSource, {inline: false});

    return (
        <div className="pw-markdown">
            {reactOutput(parseTree)}
        </div>
    )
}

export default PWMarkdown;
